import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

// Styles
import styles from "../sass/pages/portfolio.module.scss"

// Images
import netCore from "../assets/img/tech-icons/netCore.svg"
import angular from "../assets/img/tech-icons/angular.svg"
import optimize from "../assets/img/tech-icons/optimize.svg"


import montevideoShopping from "../../static/img/msLogo.png"
import puntaCarretasShopping from "../../static/img/pcsLogo.png"
import nuevoCentroShopping from "../../static/img/ncsLogo.png"
import tresCrucesShopping from "../../static/img/tcsLogo.png"
import costaUrbanaShopping from "../../static/img/cusLogo.png"
import lasPiedrasShopping from "../../static/img/lpsLogo.png"


let lang = ""

const VoyDeViaje = () => {

  lang = useIntl().locale;

  const data = useStaticQuery(graphql`
    query ImagesVoyDeViaje {
      vdvTopPicture: file( relativePath: { eq: "vdvTopPicture.jpg"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }
      vdvWireOne: file( relativePath: { eq: "vdvWireOne.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvWireTwo: file( relativePath: { eq: "vdvWireTwo.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvWireThree: file( relativePath: { eq: "vdvWireThree.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvWireFour: file( relativePath: { eq: "vdvWireFour.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvWireFive: file( relativePath: { eq: "vdvWireFive.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvWireSix: file( relativePath: { eq: "vdvWireSix.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mockupTwo: file( relativePath: { eq: "vdvMockupTwo.png"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vdvPicture: file( relativePath: { eq: "vdvPicture.jpg"} ) {
        id
        childImageSharp { 
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `);

  const title = useIntl().formatMessage({id: 'portfolio'});
  const [load] = useState(0);

  // const verticalBand = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    // verticalBand.current.style.height = document.body.offsetHeight + "px";
  }, [load]);


  return (
  <Layout color="blue">
    <SEO title={title} /> 
    {/* <div ref={ verticalBand } className={ styles.verticalBand }></div> */}

    <article> 
      <section >
        <div className={ "container-large " + styles.topCase + " " + styles.topCasevdv}>
          <div className={"grid-cards-2"}>
            <div className={styles.topTitle}>
                <h1 className="h3"><FormattedMessage id="case_vdv_title" values={{breakLine:<br/>}}/></h1>
            </div> 
            <div>
                <p className="p-small"><FormattedMessage id="case_vdv_paragraph_1" values={{breakLine:<br/>}}/></p>        
            </div>
         </div>
        </div>
        
      </section>
    </article>

    <article>
        <section className="container-large">
        <div className={styles.space}>
        <div className={"container-small " }>
          <div className={styles.picture + " " + styles.vdvTopPicture}>
              <figure>
                <Img fluid={ data.vdvTopPicture.childImageSharp.fluid } alt="Voy de Viaje picture" />
              </figure>
            </div>
            </div>
                
          </div>

        </section>
    </article>

    
    <article className={ "container-large " + styles.center }> 
        <section className={"container-small "}>
          <div>
            <h3><FormattedMessage id="case_movie_title_2" values={{breakLine:<br/>}}/></h3> 
            <p><FormattedMessage id="case_vdv_paragraph_2" values={{breakLine:<br/>}}/></p>
          </div>
          <div className={"grid-cards-2 " + styles.gridPictures}>
            <div>
            <figure>
                <Img fluid={ data.vdvWireOne.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
            <div>
            <figure>
                <Img fluid={ data.vdvWireFour.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
            <div>
            <figure>
                <Img fluid={ data.vdvWireTwo.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
            <div>
            <figure>
                <Img fluid={ data.vdvWireFive.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
            <div>
            <figure>
                <Img fluid={ data.vdvWireThree.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
            <div>
            <figure>
                <Img fluid={ data.vdvWireSix.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>
          </div>

          </section>  
          <section className={"container-small "}>
          <div className={styles.space}>
            <p><FormattedMessage id="case_vdv_paragraph_3" values={{breakLine:<br/>}}/></p>
          </div>
          <div >
            <iframe src="https://player.vimeo.com/video/424069016" width="100%" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          </div>
          <div className={styles.space}></div>
            <div className={styles.space}><h3><FormattedMessage id="case_vdv_title_2" values={{breakLine:<br/>}}/></h3> </div>
            <div className={"grid-cards-6 " + styles.center}>
            
            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={montevideoShopping} alt="Montevideo Shopping" />
              </div>
            </div>

            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={puntaCarretasShopping} alt="Punta Carretas Shopping" />
              </div>
            </div>

            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={nuevoCentroShopping} alt="Nuevo Centro Shopping" />
              </div>
            </div>

            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={tresCrucesShopping} alt="Tres Cruces Shopping" />
              </div>
            </div>

            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={costaUrbanaShopping} alt="Costa Urbana Shopping" />
              </div>
            </div>

            <div className="whiteCardSquare-Small">
              <div className="whiteCardIcon">
                <img src={lasPiedrasShopping} alt="Las Piedras Shopping" />
              </div>
            </div>
        
            </div>

            <div className={styles.vdvPicture}> 
            <figure>
                <Img fluid={ data.vdvPicture.childImageSharp.fluid } alt="Voy de Viaje Wireframe" />
              </figure>
            </div>

            <div className={styles.btnMap}>
                <button className="btn btn-main btn-red">
                  <a href="https://www.voydeviaje.uy/islas" target="_blank"><FormattedMessage id="see_it_on_the_map" /></a>
                </button>
              </div>

          </section>
          

          <section className={"container-small " + styles.space}>
          <section className={"container-small "}>
            <div className={styles.space}>
            <div className={styles.spaceSmall}></div>
              <h3><FormattedMessage id="case_movie_title_5"/></h3>
              <p><FormattedMessage id="case_vdv_paragraph_4" values={{breakLine:<br/>}}/></p>
            </div>

        <div className={"grid-cards-3 " + styles.gridThree + " " + styles.centeredCards}>
          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={netCore} alt=".Net Core" />
            </div>
            <p><FormattedMessage id="net_core"/></p>
          </div>
        
          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={angular} alt="Angular" />
            </div>
            <p><FormattedMessage id="angular"/></p>
          </div>

          <div className="whiteCardSquare">
            <div className="whiteCardIconMedium">
              <img src={optimize} alt="Optimize" />
            </div>
            <p><FormattedMessage id="optimize"/></p>
          </div>

        </div>
      </section>
    </section>
  </article>

        <article>
    <div className={styles.spaceSmall}></div>
      <div className={styles.topLineCaseVdv}></div>
        <div className={"container-large " + styles.result + " " + styles.vdvResult}>
          <div className={styles.result}>
            
          <div className={"grid-cards-2-1 "}>
            <div><h3><FormattedMessage id="case_movie_title_6" values={{breakLine:<br/>}}/></h3><br/><p><FormattedMessage id="case_vdv_paragraph_5" values={{breakLine:<br/>}}/></p>
              
              </div>
              <div className={ styles.picture + " " + styles.vdvMockupTwo}>
                  <figure>
                    <Img fixed={ data.mockupTwo.childImageSharp.fixed } alt="Voy de Viaje Mockup" />
                  </figure>
                </div>
            </div>
            
            </div>
            </div>
    </article>

    <article>  
      <div className={"bottomCards " + styles.vdvResult}>
      <div className={"container-large container-wide-mobile " + styles.result + " " + styles.vdvBottomSpace}>
      <div className="container">
      <h3><FormattedMessage id="bottom_cards_title"/></h3>
            <div className={"grid-cards-2-1 "}>
            <Link to={`/${lang}` + "/movie"}>
            <div className={'portfolioCard bottomCard ' + styles.movie}>
              <div className="cardContent">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="second_project_title" /></p>
              </div>
            </div>
          </Link>
          <Link to={`/${lang}` + "/oca"}>
            <div className={'portfolioCard bottomCard ' + styles.oca}>
              <div className="cardContent ">
                  <p className="cardHashtags"></p>
                  <p className="cardTitle"><FormattedMessage id="third_project_title"/></p>
              </div>
            </div>
          </Link>
            </div>
        </div>
        </div>
        </div>  
    </article>       
        
  </Layout>
)}

export default VoyDeViaje
